import React, { useEffect, useState } from "react";
import img from "../../assets/images/azlogomessage.jpeg";
import { Link, useNavigate } from "react-router-dom";
import { getCatDetail } from "../../services/HomeServices";
import { toast } from "react-toastify";
import { formatDate } from "../../lib/constant";

const EventDesign = ({ isHomepage }) => {
  const [eventDetails, seteventDetails] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getEventDetails();
  }, []);

  const getEventDetails = async () => {
    setisLoading(true);
    await getCatDetail()
      .then((response) => {
        setisLoading(false);
        seteventDetails(response?.data?.data?.data);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <div className="container-fluid">
      <div className="bg_white event_main">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <h2>{isHomepage ? "Events" : "Trending events"}</h2>
            </div>
            <div className="col-6">
              {" "}
              <Link to="/event-post" className="btn_post">
                Post <i className="fas fa-plus" />
              </Link>{" "}
            </div>
          </div>
        </div>
        <div className={`${!isHomepage && "container"} pt-4`}>
          <div className="event_scroll">
            {eventDetails?.map((x) => (
              <div
                className={`event_autoscroll ${
                  isHomepage ? "col-sm-2" : "col-sm-3"
                }`}
                onClick={() =>
                  navigate(`/ticket-details/${x?.location_id}/${x?.event_id}`)
                }
              >
                <div className="h_event_card autoscroll-event">
                  {" "}
                  <img
                    src={
                      x?.event?.flyer ||
                      x?.event?.sponsor_logo ||
                      x?.event?.image ||
                      img
                    }
                    style={{
                      height: "292px",
                      // height:'35vh',
                      // width: "100%",
                      width: "212px",
                    }}
                    alt="event"
                  />
                  <span>
                    <i className="far fa-calendar" /> {formatDate(x?.date)}
                  </span>
                  <p>
                    <b>{x?.event?.name}</b> {x?.location?.name}
                  </p>
                  <Link>More</Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventDesign;
