import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { object, ref, string } from "yup";
import { toast } from "react-toastify";
import { randomSecurityCode } from "../../lib/constant";
import { resetPassword, verifyToken } from "../../services/AuthServices";
import Loader from "../../containers/Loader";

const MainSection = () => {
  const [securityCode, setSecurityCode] = useState("");
  const [isPassword, setisPassword] = useState(false);
  const [isConfirmPassword, setisConfirmPassword] = useState(false);
  const [isDisable, setisDisable] = useState(false);

  const params = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
    setSecurityCode(randomSecurityCode());
    // getToken(params?.token);
  }, []);

  const AdminSchema = object().shape({
    password: string()
      .required("Please enter new password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        "Must contain number,uppercase,lowercase and special characters"
      ),

    password_confirmation: string()
      .required("Please confirm your new password") // .trim(t("Validation.ResetPassword.RequiredFiled") ?? "")
      .oneOf(
        [ref("password")],
        "New password and confirm password does not match"
      ),
  });

  const submitClicked = async (data) => {
    setisDisable(true);

    let formdata = new FormData();
    formdata.append("confirm_password", data.password_confirmation);
    formdata.append("password", data.password);
    formdata.append("token", params?.token);

    await resetPassword(formdata)
      .then((response) => {
        setisDisable(false);

        toast("Password reset Successfully", { type: "success" });
        navigate("/login");
      })
      .catch((error) => {
        setisDisable(false);

        if (error?.response?.status == 422)
          toast(error?.response?.data?.message, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error?.response.data?.message, { type: "error" });
        else
          toast(error?.response?.data?.message || "Something went wrong", {
            type: "error",
          });
      });
  };

  const { handleSubmit, handleBlur, handleChange, values, errors, touched } =
    useFormik({
      validationSchema: AdminSchema,
      initialValues: {
        password: "",
        password_confirmation: "",
        code: "",
      },
      onSubmit: async () => {
        if (values?.code === Number(securityCode)) {
          submitClicked(values);
        } else {
          toast("Security code does not match.", {
            type: "error",
          });
        }
      },

      onReset: () => {},
    });

  return (
    <div
      style={{ display: "flex", justifyContent: "center", paddingTop: "140px" }}
    >
      <div className="binduz-er-contact-us-area">
        <div className=" container">
          <div className="row" style={{ justifyContent: "center" }}>
            <>
              <div className="   col-xl-7 col-lg-9">
                <div
                  className="binduz-er-contact-us-box box-shadow"
                  style={{ padding: "0px 60px 60px" }}
                >
                  <form onSubmit={((e) => e?.preventDefault(), handleSubmit)}>
                    <div
                      className="binduz-er-contact-title"
                      style={{ paddingTop: "15px" }}
                    >
                      <h1
                        className="binduz-er-title"
                        style={{ textAlign: "center", fontSize: "30px" }}
                      >
                        RESET PASSWORD
                      </h1>
                    </div>
                    <div
                      className="row"
                      style={{
                        paddingTop: "5px",
                        paddingBottom: "35px",
                        gap: "20px",
                        justifyContent: "center",
                      }}
                    >
                      <div className=" col-lg-8">
                        <div className="binduz-er-input-box ">
                          <input
                            type={isPassword ? "text" : "password"}
                            placeholder="Password"
                            name="password"
                            id="password"
                            value={values.password}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            style={{
                              borderColor:
                                touched?.password && errors?.password && "red",
                            }}
                          />
                          {isPassword ? (
                            <i
                              className="fal fa-eye"
                              onClick={() => setisPassword(false)}
                              style={{ cursor: "pointer" }}
                            />
                          ) : (
                            <i
                              className="fal fa-eye-slash"
                              style={{ cursor: "pointer" }}
                              onClick={() => setisPassword(true)}
                            />
                          )}{" "}
                        </div>
                        {touched?.password && errors?.password && (
                          <p className="text-danger">{errors.password}</p>
                        )}
                      </div>

                      <div className=" col-lg-8">
                        <div className="binduz-er-input-box">
                          <input
                            type={isConfirmPassword ? "text" : "password"}
                            placeholder="Confirm Password"
                            name="password_confirmation"
                            id="password_confirmation"
                            value={values.password_confirmation}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            style={{
                              borderColor:
                                touched?.password_confirmation &&
                                errors?.password_confirmation &&
                                "red",
                            }}
                          />
                          {isConfirmPassword ? (
                            <i
                              className="fal fa-eye"
                              onClick={() => setisConfirmPassword(false)}
                              style={{ cursor: "pointer" }}
                            />
                          ) : (
                            <i
                              className="fal fa-eye-slash"
                              style={{ cursor: "pointer" }}
                              onClick={() => setisConfirmPassword(true)}
                            />
                          )}
                        </div>
                        {touched?.password_confirmation &&
                          errors?.password_confirmation && (
                            <span className="text-danger">
                              {errors.password_confirmation}
                            </span>
                          )}
                      </div>
                      <div
                        className="row col-lg-8 "
                        style={{
                          justifyContent: "space-between",
                          padding: "0px",
                        }}
                      >
                        <div className=" col-lg-6">
                          <div className="binduz-er-input-box mb-1">
                            <input
                              type="number"
                              placeholder="Security Code"
                              name="code"
                              id="code"
                              max={4}
                              value={values.code}
                              onBlur={handleBlur}
                              onChange={(e) => {
                                if (
                                  e?.target?.value <= 9999 &&
                                  e?.target?.value >= 0
                                ) {
                                  handleChange(e);
                                }
                              }}
                              // style={{ fontSize: "27px", paddingLeft: "15px" }}
                            />
                            {/* <i className="fal fa-eye"></i> */}
                          </div>
                        </div>
                        <div className=" col-lg-4">
                          <div
                            className="binduz-er-input-box mb-1"
                            style={{ minWidth: "130px" }}
                          >
                            <input
                              type="text"
                              placeholder="Code"
                              style={{
                                fontSize: "27px",
                                paddingLeft: "7px",
                                borderRadius: "10px",
                                textAlign: "center",
                                color: "#FF6821",
                              }}
                              disabled
                              value={securityCode}
                            />
                            {/* <i className="fal fa-eye"></i> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      disabled={isDisable}
                      onClick={handleSubmit}
                      className="binduz-er-main-btn contact-us-box-button mt-5"
                    >
                      {isDisable ? "Loading..." : "Submit"}
                    </button>
                  </form>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainSection;
