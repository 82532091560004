import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getDocotrDetail } from "../../services/HomeServices";
import { useLocation } from "react-router-dom";
import iconLocation from "../../assets/images/icon/icon_location.svg";
import iconEmail from "../../assets/images/icon/icon_email.svg";
import iconPhone from "../../assets/images/icon/icon_phone.svg";

const NewDesign = () => {
  const location = useLocation();
  const [data, setdata] = useState(location?.state);
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
    // getData()
  }, []);

  const getData = async () => {
    setisLoading(true);
    await getDocotrDetail()
      .then((response) => {
        setisLoading(false);
        setdata(response?.data?.data);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "My Website",
          text: "Check out this awesome content!",
          url: window.location.href,
        });
        console.log("Content shared successfully");
      } catch (err) {
        console.error("Error sharing the content:", err);
      }
    } else {
      alert("Sharing is not supported on this browser.");
    }
  };

  return (
    <>
      {data?.image && (
        <div className="container-fluid doctors_banner">
          <img src={data?.image} alt="doctors banner" />
        </div>
      )}
      <div className="container-fluid bg_white pt-4">
        <div className="container">
          <div className="row doct_main">
            <div className="col-sm-12">
              <h2>
                About{" "}
                <span>
                  {data?.name}, {data?.category?.name}
                </span>
              </h2>
            </div>
          </div>
          <div className="row pt-4">
            <div className="col-sm-4">
              {data?.profile_image && (
                <div className="col-sm-12">
                  <a className="doct_photo">
                    <h5>
                      {data?.name} <span>{data?.category?.name}</span>
                    </h5>
                    <img src={data?.profile_image} />
                  </a>
                </div>
              )}
              <div className="col-sm-12">
                <a className="cd_detales cd_tab1">
                  <img src={iconLocation} alt="icon" />
                  <label className="cd_mtb">
                    <b>Venue</b>
                    <p style={{ margin: "0px" }}>{data?.address}</p>
                    <p style={{ margin: "0px" }}>
                      {" "}
                      {data?.city?.city} {data?.state?.state}
                    </p>
                    <p style={{ margin: "0px" }}>{data?.zip_code}</p>{" "}
                  </label>
                </a>
                {data?.email && (
                  <a className="cd_detales cd_tab2">
                    <img src={iconEmail} alt="icon" />
                    <label className="cd_mtb">
                      <b>Email</b>
                      {data?.email}
                    </label>
                  </a>
                )}
                <a className="cd_detales cd_tab3">
                  <img src={iconPhone} alt="icon" />
                  <label className="cd_mtb">
                    <b>Mobile Number</b>
                    {data?.phone}
                  </label>
                </a>
              </div>
            </div>
            <div className="col-sm-8">
              <div className="row pt-2">
                <div className="col-sm-12">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.comment,
                    }}
                  />
                  <a onClick={handleShare} className="btn_share">
                    <i className="fas fa-share-alt" /> Share
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewDesign;
