import { useAnimation } from "framer-motion";
import React, { useCallback, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  getClassifieldCatData,
  getClassifieldData,
  getLatestClassifieldData,
} from "../../services/HomeServices";
import { toast } from "react-toastify";
import Pagination from "../../containers/Pagination";
import iconStar from "../../assets/images/prime_star.png";
import img from "../../assets/images/azlogomessage.jpeg";
import _ from "lodash";
import { formatDate, minimizeName, plainString } from "../../lib/constant";
import Loader from "../../containers/Loader";
import LatestClassifiedModule from "../../containers/NewDesignModules/LatestClassifiedModule";
import EventDesign from "../NewDesign/EventDesign";

const categoriesEmogiArray = [
  // { name: "Pin Hopes", emoji: "📌" }, // Pin
  { name: "Training Classes", emoji: "🎓" }, // Graduation Cap
  { name: "Car Pool", emoji: "🚗" }, // Car
  { name: "Food & Catering", emoji: "🍔" }, // Burger
  { name: "Beauty Services", emoji: "💄" }, // Lipstick
  { name: "Care Takers", emoji: "👩‍⚕️" }, // Healthcare Worker
  { name: "Art/Dance/Music", emoji: "🎨" }, // Palette
  { name: "Real Estate", emoji: "🏡" }, // House
  { name: "For Sale", emoji: "💰" }, // Money Bag
  { name: "Travel", emoji: "✈️" }, // Airplane
  { name: "Rentals", emoji: "🏠" }, // House (Alternative to Real Estate)
  { name: "Automobiles", emoji: "🚘" }, // Car (Alternative to Car Pool)
];

const NewDesign = () => {
  const [catData, setcatData] = useState([]);
  const [search, setsearch] = useState("");
  const [latestClassiLoading, setlatestClassiLoading] = useState(false);
  const [latestClassiData, setlatestClassiData] = useState([]);
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [page, setpage] = useState(1);
  const [links, setlinks] = useState([]);
  const [hasAnimatedLeft, setHasAnimatedLeft] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getData();
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  }, []);

  const controlsLeft = useAnimation();
  const { ref: refLeft, inView: inViewLeft } = useInView({ threshold: 0.1 });

  useEffect(() => {
    if (inViewLeft && !hasAnimatedLeft) {
      controlsLeft.start("visible");
      setHasAnimatedLeft(true); // Set the state to prevent re-triggering
    }
  }, [controlsLeft, inViewLeft]);

  const boxVariantsLeft = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.7 } },
  };

  const debouncedFetchResults = useCallback(
    _.debounce((selectedCat) => classifieldData(selectedCat), 500),
    []
  );

  useEffect(() => {
    classifieldData(params?.id);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  }, [page]);

  useEffect(() => {
    setpage(1);
    classifieldData(params?.id);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  }, [search, params?.id]);

  const getData = async () => {
    await getClassifieldCatData()
      .then((response) => {
        const array = response?.data?.data?.filter(
          (a) => a?.name !== "Pin Hopes"
        );
        setcatData(array);
        setcatData(response?.data?.data);
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const classifieldData = async (id) => {
    setisLoading(true);
    await getClassifieldData(id || params?.id, search, "", page)
      .then((response) => {
        setisLoading(false);
        setdata(response?.data?.data?.data);
        setlinks(response?.data?.data?.links);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <>
      <div className="container-fluid bg_drackgray">
        <div className="container">
          <div className="row prime_listings">
            <div className="col-6">
              <h2>Prime Listings</h2>
            </div>
            <div className="col-6">
              {" "}
              <Link to="/classifield-post" className="btn_post">
                Post <i className="fas fa-plus" />
              </Link>{" "}
            </div>
          </div>
          <div className="row classified_sc pt-3">
            <div className="col-sm-6">
              <label>Search Category</label>
            </div>
            <div className="col-sm-3">
              <select
                placeholder="Data"
                className="category"
                value={params?.id}
                onChange={(e) => navigate(`/classifield/${e?.target?.value}`)}
              >
                <option value="">Select Category</option>
                {catData?.map((x, i) => (
                  <option value={x?.id}>
                    {" "}
                    {categoriesEmogiArray[i]?.emoji}
                    {x?.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-sm-3">
              <form
                onSubmit={(e) => {
                  e?.preventDefault();
                  classifieldData(params?.id);
                }}
                className="search_box_01"
              >
                <i className="fa fa-search" aria-hidden="true" />
                <input
                  onChange={(e) => setsearch(e?.target?.value)}
                  value={search}
                  type="text"
                  name="search"
                  placeholder="Search"
                />
              </form>
            </div>
          </div>
          <div className="row pt-4">
            {!isLoading ? (
              <>
                {!_.isEmpty(data) ? (
                  <>
                    {data?.map((x) => (
                      <div
                        className="col-sm-3 pt-4"
                        onClick={() => {
                          navigate(`/classifield-detail/${x?.id}`);
                        }}
                      >
                        <div className="prime_card ">
                          {" "}
                          <img
                            src={iconStar}
                            alt="star"
                            className="prime_star pt-4"
                          />{" "}
                          <img
                            className="card-img-top"
                            style={{ height: "174px", width: "274px" }}
                            src={x?.image || x?.images[0]?.image || img}
                            alt="Card image cap"
                          />
                          <p className="card-text line-clump-2">
                            {minimizeName(plainString(x?.description), 90)}
                          </p>
                          <div className="row">
                            <div className="col-6">
                              <label>
                                <i className="far fa-calendar" />{" "}
                                {formatDate(x?.start_date)}
                              </label>
                            </div>
                            <div className="col-6">
                              <Link to={`/classifield-detail/${x?.id}`}>
                                More
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <h5 style={{ textAlign: "center", marginTop: "70px" }}>
                    No Data found
                  </h5>
                )}
              </>
            ) : (
              <Loader />
            )}
          </div>

          <Pagination links={links} setpage={setpage} />
        </div>
      </div>
      <LatestClassifiedModule />
      <EventDesign />
    </>
  );
};

export default NewDesign;
