import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { object, string } from "yup";
import { addNewsLetter } from "../../../../services/AuthServices";
import { getCatDetail } from "../../../../services/HomeServices";
import eventLogo from "../../../../assets/images/event.png";
import { formatDate } from "../../../../lib/constant";
import FooterDesign from "../../../NewDesign/FooterDesign";


function Footer() {
  const [isDisable, setisDisable] = useState(false);
   const [eventDetails, seteventDetails] = useState([]);
   const [isLoading, setisLoading] = useState(false);

   const navigate = useNavigate();

   useEffect(() => {
     getCatDetails();
   }, []);

   const getCatDetails = async (id) => {
     setisLoading(true);
     await getCatDetail(id, "")
       .then((response) => {
         setisLoading(false);

         seteventDetails(response?.data?.data?.data);
       })
       .catch((error) => {
         setisLoading(true);
         toast("Something went wrong", { type: "error" });
       });
   };
   const onClickEvent = (e) => {
     navigate(`/ticket-details/${e?.location_id}/${e?.event_id}`);
   };

  const AdminSchema = object().shape({
    email: string()
      .required("Please enter email.")
      .email("Invalid Email Format. Please try again.")
      .trim("Please enter email."),

    name: string()
      .required("Please enter Your Name.")
      .trim("Please enter Your Name."),
  });

  const submitClicked = async (data, resetForm) => {
    setisDisable(true);
    let formdata = new FormData();
    formdata.append("email", values?.email);
    formdata.append("name", values?.name);

    await addNewsLetter(formdata)
      .then((response) => {
        toast("News Letter added Successfully", { type: "success" });
        setisDisable(false);
        resetForm();
      })
      .catch((error) => {
        setisDisable(false);
        if (error?.response?.status == 422)
          toast(error?.response?.data?.message, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error?.response?.data?.message, { type: "error" });
        else
          toast(error?.response?.data?.message || "Something went wrong", {
            type: "error",
          });
      });
  };

  const { handleSubmit, handleBlur, handleChange, values, errors, touched } =
    useFormik({
      validationSchema: AdminSchema,

      initialValues: {
        email: "",
        name: "",
      },
      onSubmit: async (values, { resetForm }) => {
        submitClicked(values, resetForm);
      },

      onReset: () => {},
    });
  return (
    // <>
    //   <footer className="binduz-er-footer-area">
    //     <div className="container">
    //       <div className="row">
    //         <div className="col-lg-9">
    //           <div className="row">
    //             <div className="col-lg-4">
    //               <div className="binduz-er-footer-widget-style-1">
    //                 <div className="binduz-er-footer-title">
    //                   <h3 className="binduz-er-title">Categories</h3>
    //                 </div>
    //                 <div className="binduz-er-footer-menu-list">
    //                   <ul>
    //                     <li>
    //                       <Link to="/">Home</Link>
    //                     </li>
    //                     <li>
    //                       <Link to="/calender">Events</Link>
    //                     </li>
    //                     <li>
    //                       <Link to="/classifield">Classifieds</Link>
    //                     </li>
    //                     <li>
    //                       <Link to="/local-bussiness">Businesses</Link>
    //                     </li>
    //                     <li>
    //                       <Link to="/doctor">Doctor</Link>
    //                     </li>
    //                     <li>
    //                       <Link to="/services">Services</Link>
    //                     </li>
    //                   </ul>
    //                   <ul>
    //                     <li>
    //                       <Link to="/organization">Organization</Link>
    //                     </li>
    //                     <li>
    //                       <Link to="/Contact">Contact Us</Link>
    //                     </li>
    //                     <li>
    //                       <Link to="/about">About Us</Link>
    //                     </li>
    //                     <li>
    //                       <Link to="/ads-information">Advertise with us</Link>
    //                     </li>
    //                     <li>
    //                       <Link to="/privacy-policy">Privacy Policy</Link>
    //                     </li>
    //                   </ul>
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="col-lg-4 ">
    //               <div className="binduz-er-footer-widget-style-1">
    //                 <div className="binduz-er-footer-title">
    //                   <h3 className="binduz-er-title">Newsletter</h3>
    //                 </div>
    //                 <div className="binduz-er-footer-widget-form">
    //                   <form onSubmit={handleSubmit}>
    //                     <div className="binduz-er-input-box">
    //                       <i
    //                         className="fal fa-user"
    //                         style={{
    //                           top: touched?.name && errors?.name ? "35%" : "",
    //                         }}
    //                       ></i>
    //                       <input
    //                         type="text"
    //                         placeholder="Enter Your Name"
    //                         name="name"
    //                         id="name"
    //                         value={values.name}
    //                         onBlur={handleBlur}
    //                         onChange={handleChange}
    //                         style={{
    //                           borderColor:
    //                             touched?.name && errors?.name && "red",
    //                         }}
    //                       />
    //                       {touched?.name && errors?.name && (
    //                         <span className="text-danger">{errors.name}</span>
    //                       )}
    //                     </div>

    //                     <div className="binduz-er-input-box">
    //                       <i
    //                         className="fal fa-envelope"
    //                         style={{
    //                           top: touched?.email && errors?.email ? "35%" : "",
    //                         }}
    //                       ></i>
    //                       <input
    //                         type="email"
    //                         name="email"
    //                         id="email"
    //                         value={values.email}
    //                         onBlur={handleBlur}
    //                         onChange={handleChange}
    //                         style={{
    //                           borderColor:
    //                             touched?.email && errors?.email && "red",
    //                         }}
    //                         placeholder="Enter Email Address"
    //                       />
    //                       {touched?.email && errors?.email && (
    //                         <span className="text-danger">{errors.email}</span>
    //                       )}
    //                     </div>

    //                     <div className="binduz-er-input-box">
    //                       <button type="submit">
    //                         <i className="fal fa-paper-plane"></i>{" "}
    //                         {isDisable ? "Loading..." : "Subscribe Now"}
    //                       </button>
    //                     </div>
    //                   </form>
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="col-lg-4 col-md-6">
    //               <div className="binduz-er-footer-widget-style-3">
    //                 <div className="binduz-er-footer-title">
    //                   <h3 className="binduz-er-title">Events</h3>
    //                 </div>
    //                 <div className="binduz-er-footer-widget-feeds">
    //                   <div className="binduz-er-sidebar-latest-post-box">
    //                     {eventDetails?.slice(0, 3)?.map((x) => (
    //                       <div className="binduz-er-sidebar-latest-post-item">
    //                         <div className="binduz-er-thumb col-lg-3">
    //                           <img
    //                             src={x?.event?.flyer||eventLogo}
    //                             width={90}
    //                             height={90}
    //                             alt="latest"
    //                           />
    //                         </div>
    //                         <div className="binduz-er-content col-lg-9">
    //                           <span>
    //                             <i className="fal fa-calendar-alt"></i>{" "}
    //                            { formatDate(x?.date)}
    //                           </span>
    //                           <h4 className="binduz-er-title" style={{cursor:'pointer'}} onClick={()=>{
    //                                 navigate(
    //                                   `/ticket-details/${x?.location_id}/${x?.event_id}`
    //                                 );

    //                           }}>
    //                             <a>{x?.event?.name}</a>
    //                           </h4>
    //                         </div>
    //                       </div>
    //                     ))}
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //         <div className="col-lg-3">
    //           <div className="binduz-er-footer-widget-info">
    //             <div className="binduz-er-logo">
    //               <a>
    //                 <img
    //                   src={
    //                     require("../../../../assets/images/az-logo.png").default
    //                   }
    //                   alt=""
    //                 />
    //               </a>
    //             </div>
    //             <div className="binduz-er-text">
    //               <p>
    //                 © AZIndia.com 1999-2024 AZIndia.com is not affiliated to any
    //                 businesses that are listed or advertised on this site and
    //                 will not be liable for any products/services aquired from
    //                 them.
    //               </p>
    //             </div>
    //             <div className="binduz-er-social">
    //               <ul>
    //                 <li>
    //                   <a>
    //                     <i className="fab fa-facebook-f"></i>
    //                   </a>
    //                 </li>
    //                 <li>
    //                   <a>
    //                     <i className="fab fa-twitter"></i>
    //                   </a>
    //                 </li>
    //                 <li>
    //                   <a>
    //                     <i className="fab fa-behance"></i>
    //                   </a>
    //                 </li>
    //                 <li>
    //                   <a>
    //                     <i className="fab fa-youtube"></i>
    //                   </a>
    //                 </li>
    //               </ul>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="binduz-er-back-to-top">
    //       <p>
    //         BACK TO TOP <i className="fal fa-long-arrow-right"></i>
    //       </p>
    //     </div>
    //   </footer>
    //   <div className="binduz-er-footer-copyright-area">
    //     <div className="container">
    //       <div className="row align-items-center">
    //         <div className="col-lg-6">
    //           <div className="binduz-er-copyright-text">
    //             <p>
    //               Copyright By @
    //               <span>
    //                 <a
    //                   style={{ color: "white" }}
    //                   // href="https://wa.me/+919376002929"
    //                   // target="_blank"
    //                 >
    //                   AZINDIA
    //                 </a>
    //               </span>{" "}
    //               - 2024
    //             </p>
    //           </div>
    //         </div>
    //         <div className="col-lg-6">
    //           <div className="binduz-er-copyright-menu float-lg-end float-none">
    //             <ul>
    //               <li>
    //                 <Link to="/privacy-policy">Privacy & Policy</Link>
    //               </li>
    //               <li>
    //                 <Link to="/about">About Us</Link>
    //               </li>
    //               <li>
    //                 <Link to="/ads-information">Advertise with Us</Link>
    //               </li>
    //             </ul>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </>
    <FooterDesign />
  );
}

export default Footer;
