import React, { useEffect, useState } from "react";
import img from "../../assets/images/azlogomessage.jpeg";
import mc_img_01 from "../../assets/images/mc_img_01.png";
import mc_img_02 from "../../assets/images/mc_img_02.png";
import mc_img_03 from "../../assets/images/mc_img_03.png";
import mc_img_04 from "../../assets/images/mc_img_04.png";
import { getMsgData } from "../../services/HomeServices";
import { toast } from "react-toastify";
import { formatDateToMMDDYYYY } from "../../lib/constant";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../containers/Loader";

const MessageCenter = ({ toggle }) => {
  const [messageData, setmessageData] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [search, setsearch] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    getMessageData();
  }, [toggle, search]);

  const getMessageData = async () => {
    setisLoading(true);

    await getMsgData(search, "", 1)
      .then((response) => {
        setisLoading(false);
        setmessageData(response?.data?.data?.data);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };
  return (
    <div className="container-fluid mc_main bg_gray">
      <div className="container">
        <div className="row">
          <div className="col-sm-6">
            <h2>Message Center</h2>
          </div>
          <div className="col-sm-6">
            <div className="search_box_01">
              {" "}
              <i className="fa fa-search" aria-hidden="true" />
              <input
                type="text"
                name="search"
                placeholder="Search..."
                value={search}
                onChange={(e) => setsearch(e?.target?.value)}
              />
            </div>
          </div>
        </div>
        {!isLoading ? (
          <div className="row pt-4">
            {messageData?.slice(0, 4)?.map((item) => {
              let profileImage;
              if (item?.type === "assoiciation") {
                profileImage = item?.user?.profile_image;
              }
              let imagePath = item?.image || profileImage || img;
              return (
                <div
                  className="col-sm-3"
                  onClick={() => {
                    navigate(`/ticket-details/${item?.id}`, { state: item });
                  }}
                >
                  <div className="mc_card">
                    {" "}
                    <img src={imagePath || img} alt="img" />{" "}
                    <span>
                      {formatDateToMMDDYYYY(new Date(item?.created_at))}
                    </span>
                    {/* <div className="line-clump-2"> */}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item?.message,
                      }}
                    />
                    {/* </div> */}
                    {/* <a href="#">More</a> */}
                  </div>
                </div>
              );
            })}
            {/* <Link to="/message-center" className="btn_seeall">
              See All <i className="fas fa-chevron-right" />
            </Link>{" "} */}
          </div>
        ) : (
          <div
            style={{
              minHeight: "450px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader />
          </div>
        )}
        <Link to="/message-center" className="btn_seeall">
          See All <i className="fas fa-chevron-right" />
        </Link>{" "}
      </div>
    </div>
  );
};

export default MessageCenter;
