import React, { useEffect, useState } from "react";
import doctorBanner from "../../assets/images/doctors_banner.png";
import { getDoctorData, getDoctorList } from "../../services/HomeServices";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "../../containers/Loader";
import { plainString } from "../../lib/constant";
import _ from "lodash";
import Pagination from "../../containers/Pagination";

const NewDesign = () => {
  const [catSearch, setcatSearch] = useState("");
  const [search, setsearch] = useState("");
  const [data, setdata] = useState([]);
  const [catId, setcatId] = useState();
  const [catIsLoading, setcatIsLoading] = useState(false);
  const [doctorList, setdoctorList] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [page, setpage] = useState(1);
  const [catName, setcatName] = useState("");
  const [links, setlinks] = useState([]);
  const [firstTimeCalled, setfirstTimeCalled] = useState(false);
  const navigate = useNavigate();

  let widthOfPage = window.innerWidth > 700;

  useEffect(() => {
    getDoctors();
  }, [catSearch]);

  useEffect(() => {
    if (catId) {
      if (firstTimeCalled) {
        getData();
      }
    }
    return () => {};
  }, [page]);

  useEffect(() => {
    if (catId) {
      if (firstTimeCalled) {
        setpage(1);
        getData();
      }
    }
    return () => {};
  }, [search, catId]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  }, []);

  const getData = async (id) => {
    setisLoading(true);
    let categoryId = id || catId;
    await getDoctorData(categoryId, search, page)
      .then((response) => {
        let resData = response?.data?.data?.data;
        setdata(resData);
        setlinks(response?.data?.data?.links);
        setisLoading(false);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  const getDoctors = async () => {
    setcatIsLoading(true);
    await getDoctorList(catSearch?.trim())
      .then((response) => {
        setcatIsLoading(false);
        setdoctorList(response?.data?.data);
        if (!firstTimeCalled) {
          setcatId(response?.data?.data[0]?.id);
          getData(response?.data?.data[0]?.id);
          setcatName(response?.data?.data[0]?.name);
        }
        setfirstTimeCalled(true);
      })
      .catch((error) => {
        setcatIsLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <>
      <div className="container-fluid doctors_banner">
        <img src={doctorBanner} alt="doctors banner" />
      </div>
      <div className="container-fluid pt-4">
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <div className="doct_categ">
                <h2>Categories</h2>
                {!catIsLoading ? (
                  <div className="dc_scroll">
                    {doctorList?.map((x) => (
                      <a
                        onClick={() => {
                          setcatId(x?.id);
                          setcatName(x?.name);
                        }}
                        // className={` ${catId === x?.id && "active"}`}
                      >
                        {x?.name} <span> {x?.doctors_count || 0}</span>
                      </a>
                    ))}
                  </div>
                ) : (
                  <div className="pt-5">
                    <Loader />
                  </div>
                )}
              </div>
            </div>
            <div className="col-sm-8">
              <div className="row doct_main">
                <div className="col-sm-6">
                  <h2>Doctors</h2>
                </div>
                <div className="col-sm-6">
                  <form
                    className="s_box"
                    onSubmit={(e) => {
                      e?.preventDefault();
                      getData();
                    }}
                  >
                    {" "}
                    <i className="fa fa-search" aria-hidden="true" />
                    <input
                      value={search}
                      onChange={(e) => setsearch(e?.target?.value)}
                      type="text"
                      name="search"
                      placeholder="Search"
                    />
                  </form>
                </div>
              </div>
              <div className="row pt-5">
                {!isLoading ? (
                  <>
                    {!_.isEmpty(data) ? (
                      <>
                        {data?.map((x) => (
                          <div
                            className="col-sm-3"
                            style={{ display: "flex", alignItems: "end" }}
                            onClick={() =>
                              navigate(`/doctor/${x?.id}`, { state: x })
                            }
                          >
                            <a
                              className={`${
                                x?.profile_image ? "doct_photo" : "doct_name"
                              }`}
                            >
                              <h5>
                                {plainString(x?.name)}{" "}
                                <span >
                                  {" "}
                                  {x?.category?.name}
                                </span>
                              </h5>
                              {x?.profile_image && (
                                <img src={x?.profile_image} />
                              )}
                            </a>
                          </div>
                        ))}
                        <Pagination links={links} setpage={setpage} />
                      </>
                    ) : (
                      <h5 style={{ marginTop: "80px", textAlign: "center" }}>
                        No Data Found
                      </h5>
                    )}
                  </>
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewDesign;
