import React, { useEffect, useState } from "react";
import img from "../../assets/images/azlogomessage.jpeg";

import { getSponsorIMG } from "../../services/HomeServices";
import { toast } from "react-toastify";
import { motion, useAnimation } from "framer-motion";

const AdSlider = () => {
  const [data, setdata] = useState([]);
  const [isHover, setisHover] = useState(false);

  useEffect(() => {
    getSponsorImage();
  }, []);

  const getSponsorImage = async () => {
    await getSponsorIMG()
      .then((response) => {
        let array = [];
        for (let a of response?.data?.data) {
          if (a?.above_classified === "1") {
            array?.push(a);
          }
        }
        setdata(response?.data?.data);
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };


  return (
    <div className="container-fluid bg_222">
      <div className="marquee-wrapper">
        <div className="marquee-block">
          <div
            className={`marquee-inner ${"to-left"} `}
            style={{
              animationPlayState: isHover ? "paused " : "running",
            }}
          >
            {" "}
            <span>
              {data?.map((x) => (
                <motion.div
                  onMouseEnter={() => setisHover(true)}
                  onMouseLeave={() => setisHover(false)}
                  whileHover={{ scale: 1.2 }} // Scale up the image on hover
                  transition={{ type: "spring", stiffness: 300 }} // Smooth transition with spring effect
                  className="ad_card"
                  onclick="navigateToPage('index.html')"
                >
                  <a href={x?.url} target="blank">
                    <img
                      src={x?.image}
                      alt="ad"
                      height={"114px"}
                      width={"250px"}
                    />{" "}
                  </a>
                </motion.div>
              ))}
            </span>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdSlider;
