import React from "react";
import FullCalender from "./FullCalender";
import { formatDate, formatDateToMMYYYY } from "../../lib/constant";

const NewCalendar = ({
  eventCatData,
  data,
  selectedMonth,
  setselectedMonth,
  selectedEventCat,
  setselectedEventCat,
}) => {
  const monthManipulater = (eventDate) => {
    const date = new Date(eventDate);
    // Extract the month and year
    const month = date?.getMonth() + 1; // Months are zero-based (0 = January)
    const year = date?.getFullYear();

    // Format to "MM/YYYY"
    const formattedDate = `${month.toString().padStart(2, "0")}/${year}`;

    return formattedDate;
  };

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const weekday = days[new Date()?.getDay()];
  return (
    <div className="container-fluid">
      <div className="bg_white event_main">
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <div className="calendar_filter">
                <h3>
                  <span>{weekday}</span>{formatDate(new Date())}
                </h3>
                <label>Search for an event</label>
                <input
                  type="date"
                  //   placeholder="December 2024"
                  //   defaultValue="December 2024"
                  onChange={(e) =>
                    setselectedMonth(monthManipulater(e?.target?.value))
                  }
                  name="date"
                />
                <select
                  placeholder="Data"
                  name="country"
                  value={selectedEventCat}
                  onChange={(e) => setselectedEventCat(e?.target?.value)}
                  className="category"
                >
                  <option value="">Select Category</option>
                  {eventCatData?.map((x) => (
                    <option value={x?.id}>{x?.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-sm-9">
              {/* <div id="calendar"></div> */}
              <FullCalender
                data={data}
                selectedMonth={selectedMonth}
                setselectedMonth={(e) =>
                  setselectedMonth(formatDateToMMYYYY(e))
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCalendar;
