/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dateFormat from "../../lib/dateFormat";
import AccountDetailEdit from "./AccountDetailEdit";
import EmailModule from "./EmailModule";
import ChangePasswordModule from "./ChangePasswordModule";
import JobDetails from "./JobDetails";
import {
  getAccountData,
  getCityData,
  getStateData,
} from "../../services/AuthServices";
import _ from "lodash";
import Loader from "../../containers/Loader";
import EventDetails from "./EventDetails";
import ClassifieldDetails from "./ClassifieldDetails";
import LocalBussinessDetails from "./LocalBussinessDetails";
import { getPremiumAmount } from "../../services/HomeServices";
// import { accData } from "../../lib/constant";
import MegaphoneDetails from "./MegaphoneDetails";

function AccountDetailLayout() {
  const [selectedTab, setselectedTab] = useState(0);
  const [stateData, setstateData] = useState([]);
  const [amountData, setamountData] = useState([]);
  const [cityData, setcityData] = useState([]);
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    getState();
    getAmount();
  }, []);

  const getState = async () => {
    await getStateData()
      .then((response) => {
        setstateData(response?.data?.data);
        getData(response?.data?.data);
      })
      .catch((error) => {});
  };

  const getAmount = async () => {
    await getPremiumAmount()
      .then((response) => {
        setamountData(response?.data?.data);
      })
      .catch((error) => {});
  };

  const getData = async (stateList) => {
    setisLoading(true);
    await getAccountData()
      .then((response) => {
        setisLoading(false);
        setdata(response?.data?.data);
        let code;
        for (let a of stateList) {
          if (a?.id == Number(response?.data?.data?.state_id)) {
            code = a?.state_code;
          }
        }
        getCity(code);
      })
      .catch((error) => {
        setisLoading(true);
      });
  };

  const getCity = async (code) => {
    await getCityData(code)
      .then((response) => {
        setcityData(response?.data?.data);
      })
      .catch((error) => {});
  };

  const tabHandler = (e, value) => {
    e.preventDefault();
  };

  const namesOftheField = [
    {
      name: "My Information",
    },
    {
      name: "My Classified",
    },

    {
      name: "Megaphone",
    },
    {
      name: "JobsBoard",
    },
    {
      name: "Local Businesses",
    },
    {
      name: "My Events",
    },
    {
      name: "Change Password",
    },
  ];
  return (
    <>
      <div className="binduz-er-trending-news-topbar d-block d-md-flex justify-content-between align-items-center">
        <div className="binduz-er-trending-box">
          <div className="binduz-er-title">
            <h3 className="binduz-er-title">Account Details</h3>
          </div>
        </div>
      </div>
      {/* <div className="acc-detail-text">
        <ul className="nav nav-pills " id="pills-tab" role="tablist">
          {namesOftheField?.map((item, i) => (
            <li
              key={item.id + Math.random()}
              className="nav-item"
              role="presentation"
            >
              <a
                className={`nav-link 
                  
                  `}
                style={{ color: `${i === 0 && "black"}` }}
                onClick={(e) => tabHandler(e, item.id)}
              >
                {item?.name}
              </a>
            </li>
          ))}
        </ul>
      </div> */}
      <div className="col-lg-12">
        <div className="binduz-er-news-categories-tab">
          <ul
            className="nav nav-pills justify-content-center"
            id="pills-tab"
            role="tablist"
          >
            {namesOftheField.map((catItem, index) => (
              <li
                key={catItem.id}
                className="nav-item"
                style={{
                  cursor: "pointer",
                  backgroundColor: selectedTab === index ? "#ff6821" : "",
                }}
              >
                <a
                  onClick={() => setselectedTab(index)}
                  className={`nav-link  m-1`}
                  style={{ color: selectedTab === index ? "white" : "" }}
                >
                  {catItem.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 ">
          {!isLoading ? (
            <>
              {selectedTab === 0 && !_.isEmpty(data) && (
                <AccountDetailEdit
                  data={data}
                  stateData={stateData}
                  cityData={cityData}
                  getCity={getCity}
                />
              )}

              {selectedTab === 4 && (
                <LocalBussinessDetails
                  setselectedTab={setselectedTab}
                  data={data?.business}
                  amountData={amountData}
                />
              )}
              {selectedTab === 3 && (
                <JobDetails
                  setselectedTab={setselectedTab}
                  data={data?.job}
                  amountData={amountData}
                />
              )}
              {selectedTab === 2 && (
                <MegaphoneDetails
                  setselectedTab={setselectedTab}
                  data={data?.megaphone}
                  amountData={amountData}
                />
              )}
              {selectedTab === 1 && (
                <ClassifieldDetails
                  setselectedTab={setselectedTab}
                  data={data?.classified}
                  amountData={amountData}
                />
              )}

              {selectedTab === 5 && (
                <EventDetails
                  setselectedTab={setselectedTab}
                  data={data?.event}
                  amountData={amountData}
                />
              )}
              {selectedTab === 6 && (
                <ChangePasswordModule setselectedTab={setselectedTab} />
              )}
            </>
          ) : (
            <div style={{ marginTop: "60px" }}>
              <Loader />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default AccountDetailLayout;
