import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getThingsToDo } from "../../services/HomeServices";
import Loader from "../../containers/Loader";
import SideADModule from "../../containers/PostCards/SideADModule";
import _ from "lodash";

const MainSection = () => {
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  // const [catData, setcatData] = useState([]);
  const [selectedItem, setselectedItem] = useState({});

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setisLoading(true);
    await getThingsToDo()
      .then((response) => {
        setdata(response?.data?.data);
        setselectedItem(response?.data?.data[0]);
        setisLoading(false);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <section
      className={`binduz-er-latest-news-area `}
      style={{ paddingTop: "30px" }}
    >
      <div className=" container">
        <div className="row">
          {/* <Breadcrumb title="Things To Do" /> */}
          {!isLoading && (
            <div
              className=" col-lg-3"
              // style={{ paddingRight: "85px", paddingLeft: "55px" }}
            >
              <div className="binduz-er-top-news-title">
                <h3 className="binduz-er-title">
                  <i
                    className="far fa-hotel"
                    style={{ color: "#FF6821", marginRight: "5px" }}
                  ></i>
                  THINGS TO DO
                </h3>
              </div>
              <div className="binduz-er-social-list">
                <div className="binduz-er-list">
                  {data?.map((x) => (
                    <a className="category-search">
                      <span>
                        <span
                          style={{
                            color: `${
                              selectedItem?.id === x?.id ? "#FF6821" : ""
                            }`,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setselectedItem(x);
                          }}
                        >
                          {x?.place}
                        </span>
                      </span>
                    </a>
                  ))}
                </div>
              </div>
            </div>
          )}
          {!isLoading ? (
            <div
              className=" col-lg-6"
              style={{ paddingRight: "40px", paddingLeft: "40px" }}
            >
              <div className="binduz-er-content">
                <h3 className="binduz-er-title">
                  <div className="binduz-er-top-news-title">
                    <h3 className="binduz-er-title">{selectedItem?.place}</h3>
                  </div>
                </h3>
                {!_.isEmpty(selectedItem?.image) && (
                  <img
                    src={selectedItem?.image[0]?.image}
                    alt="BannerImage"
                    className="img-fluid  shadow mt-4 p-1"
                    style={{
                      borderRadius: "20px",
                      width: "100%",
                      height: "45vh",
                      // objectFit:'contain',
                      // backgroundColor: "black",
                      // border: "1px solid black",
                    }}
                  />
                )}
                <div className="binduz-er-text mt-4">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: selectedItem?.description,
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div style={{ marginTop: "101px" }} className=" col-lg-9">
              <Loader />
            </div>
          )}
          <div className="col-lg-3">
            <SideADModule isClassifield={true} isEvent={true} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSection;
