import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { formatDate, formatDateToMMYYYY } from "../../lib/constant";
import { getCatDetail, getEventCatData } from "../../services/HomeServices";
import { toast } from "react-toastify";
import img from "../../assets/images/azlogomessage.jpeg";
import Loader from "../../containers/Loader";
import Pagination from "../../containers/Pagination";
import LatestClassifiedModule from "../../containers/NewDesignModules/LatestClassifiedModule";
import EventDesign from "../NewDesign/EventDesign";
import AdSlider from "../NewDesign/AdSlider";
import ClassifiedDesign from "../NewDesign/ClassifiedDesign";
import NewCalendar from "./NewCalendar";

const NewDesign = () => {
  const [eventDetails, seteventDetails] = useState([]);
  const [calendarDetails, setcalendarDetails] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [selectedEventCat, setselectedEventCat] = useState();
  const [selectedMonth, setselectedMonth] = useState(new Date());
  const [eventCatData, seteventCatData] = useState([]);
  const [page, setpage] = useState(1);
  const [links, setlinks] = useState([]);
  const [toggle, settoggle] = useState(false);
  const navigate = useNavigate();
  let widthOfPage = window.innerWidth > 1000;

  useEffect(() => {
    getEventDetails();
  }, [page]);

  useEffect(() => {
    getCatData();
  }, []);

  useEffect(() => {
    // seteventDetails([])
    getCatDetails();
  }, [selectedMonth, selectedEventCat]);

  const getEventDetails = async () => {
    setisLoading(true);
    await getCatDetail("", "", "", page)
      .then((response) => {
        setisLoading(false);
        seteventDetails(response?.data?.data?.data);
        setlinks(response?.data?.data?.links);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  const getCatData = async () => {
    await getEventCatData()
      .then((response) => {
        seteventCatData(response.data.data);
      })
      .catch((error) => {});
  };

  const getCatDetails = async () => {
    // await getCatDetail(selectedEventCat, formatDateToMMYYYY(selectedMonth))
    await getCatDetail(selectedEventCat, selectedMonth)
      .then((response) => {
        setcalendarDetails([]);
        setcalendarDetails(response?.data?.data?.data);
      })
      .catch((error) => {});
  };

  return (
    <>
      <div className="container-fluid">
        <div className="bg_white event_main">
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <h2>{!toggle ? "Events" : "Event Calendar"}</h2>
              </div>
              <div className="col-sm-6">
                {" "}
                <Link
                  to="/event-post"
                  className="btn_post_event"
                  style={{ color: "white" }}
                >
                  Post An Event <i className="fas fa-plus" />
                </Link>{" "}
                <a className="btn_event_cal" onClick={() => settoggle(!toggle)}>
                  {!toggle ? " Events Calendar" : "Events"}{" "}
                  <i className="far fa-calendar" />
                </a>{" "}
              </div>
            </div>
          </div>
          {!toggle ? (
            <div className="container pt-4">
              <div className="row">
                {!isLoading ? (
                  <>
                    {eventDetails?.slice(0, 10)?.map((x) => (
                      <div className="col-sm-3">
                        <div className="event_card">
                          {" "}
                          <img
                            style={{ height: "42vh", width: "100%" }}
                            src={
                              x?.event?.flyer ||
                              x?.event?.sponsor_logo ||
                              x?.event?.image ||
                              img
                            }
                            alt="event"
                          />{" "}
                          <span>
                            <i className="far fa-calendar" />{" "}
                            {formatDate(x?.date)}
                          </span>
                          <p>
                            <b>{x?.event?.name}</b> {x?.location?.name}
                          </p>
                          <Link
                            to={
                              `/ticket-details/${x?.location_id}/${x?.event_id}`
                            }
                          >
                            More
                          </Link>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div
                    style={{
                      minHeight: "450px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Loader />
                  </div>
                )}
              </div>

              <Pagination links={links} setpage={setpage} />
            </div>
          ) : (
            <NewCalendar
              eventCatData={eventCatData}
              data={calendarDetails}
              setselectedEventCat={setselectedEventCat}
              selectedEventCat={selectedEventCat}
              setselectedMonth={setselectedMonth}
              selectedMonth={selectedMonth}
            />
          )}
        </div>
      </div>
      {!toggle ? (
        <>
          <LatestClassifiedModule isOnlyClassified={true} />
          <EventDesign />
        </>
      ) : (
        <>
          <AdSlider />
          <ClassifiedDesign onlyClassified={true} />
        </>
      )}
    </>
  );
};

export default NewDesign;
