import React, { useEffect, useState } from "react";
import iconUser from "../../assets/images/icon/icon_user.svg";
import iconTime from "../../assets/images/icon/icon_date_time.svg";
import iconEmail from "../../assets/images/icon/icon_email.svg";
import iconPhone from "../../assets/images/icon/icon_phone.svg";
import iconName from "../../assets/images/icon/icon_name.svg";
import { toast } from "react-toastify";
import { formatDate, isImageOrVideo } from "../../lib/constant";
import { getClassifieldDetail } from "../../services/HomeServices";
import { useParams } from "react-router-dom";
import _ from "lodash";
import Loader from "../../containers/Loader";
import AdSlider from "../NewDesign/AdSlider";
import LatestClassifiedModule from "../../containers/NewDesignModules/LatestClassifiedModule";
// import ClassifiedCategoryModule from "../../containers/NewDesignModules/ClassifiedCategoryModule";

const NewDesign = () => {
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  const params = useParams();

  useEffect(() => {
    getData();
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  }, [params]);

  const getData = async () => {
    setisLoading(true);
    await getClassifieldDetail(params?.id)
      .then((response) => {
        setisLoading(false);
        setdata(response?.data?.data);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  const manipulateArray = (arr) => {
    const array =
      arr?.map((a) => ({
        ...a,
        isImage: isImageOrVideo(a?.image) === "image",
      })) || [];
    return array;
  };

  return (
    <>
      {(!_.isEmpty(data?.images) || !_.isEmpty(data?.image)) && (
        <div className="container-fluid clasifid_banner">
          <img
            style={{ height: "57vh" }}
            src={data?.image || data?.images[0]?.image}
            alt="classified banner"
          />
        </div>
      )}
      <div className="container-fluid bg_white">
        <div className="container">
          <div className="row">
            {!isLoading ? (
              <>
                {data?.name && (
                  <div className="col-sm-4">
                    <a className="cd_detales cd_tab1">
                      <img src={iconUser} alt="icon" />
                      <label className="cd_mtb">
                        <b>Name</b>
                        {data?.name}
                      </label>
                    </a>
                  </div>
                )}
                {data?.contact_person_name && (
                  <div className="col-sm-4">
                    <a className="cd_detales cd_tab2">
                      <img src={iconName} alt="icon" />
                      <label className="cd_mtb">
                        <b>Contact Person Name</b>
                        {data?.contact_person_name}
                      </label>
                    </a>
                  </div>
                )}
                {data?.phone_number && (
                  <div className="col-sm-4">
                    <a className="cd_detales cd_tab3">
                      <img src={iconPhone} alt="icon" />
                      <label className="cd_mtb">
                        <b>Mobile Number</b>
                        {data?.phone_number}
                      </label>
                    </a>
                  </div>
                )}
                {data?.email && (
                  <div className="col-sm-4">
                    <a
                      href={`mailto:${data?.email}`}
                      className="cd_detales cd_tab4"
                    >
                      <img src={iconEmail} alt="icon" />
                      <label className="cd_mtb">
                        <b>Email</b>
                        {data?.email}
                      </label>
                    </a>
                  </div>
                )}
                {data?.start_date && (
                  <div className="col-sm-4">
                    <a className="cd_detales cd_tab5">
                      <img src={iconTime} alt="icon" />
                      <label className="cd_mtb">
                        <b>Posted On</b>
                        {formatDate(new Date(data?.start_date))}
                      </label>
                    </a>
                  </div>
                )}
                <div className="col-sm-12 pt-3 cl_pera">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.description,
                    }}
                  />
                  <span>
                    When you call don't forget to mention you found this ad from
                    azindia.com
                  </span>
                </div>
              </>
            ) : (
              <div
                style={{
                  height: "300px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Loader />
              </div>
            )}
          </div>
        </div>
      </div>
      {!isLoading && (
        <>
          <AdSlider />
          <LatestClassifiedModule />
        </>
      )}
    </>
  );
};

export default NewDesign;
