import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { boolean, object, ref, string } from "yup";
import {
  formatDateToMMDDYYYY,
  formateMobileNumber,
  randomSecurityCode,
  validateNum,
} from "../../lib/constant";
import { toast } from "react-toastify";
import {
  getCityData,
  getStateData,
  verifyEmailByOTP,
} from "../../services/AuthServices";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import OTPpopUp from "./OTPpopUp";
import _, { isEmpty } from "lodash";

const MainSection = () => {
  const [securityCode, setSecurityCode] = useState("");
  const [stateData, setstateData] = useState([]);
  const [cityData, setcityData] = useState([]);
  const [isPassword, setisPassword] = useState(false);
  const [isConfirmPassword, setisConfirmPassword] = useState(false);
  // const [isVerified, setisVerified] = useState(false);
  // const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isDisable, setisDisable] = useState(false);
  const [selectedImage, setselectedImage] = useState(null);
  // const [verifiedEmail, setverifiedEmail] = useState("");

  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
    setSecurityCode(randomSecurityCode());
    getState();
  }, []);

  const getState = async () => {
    await getStateData()
      .then((response) => {
        setstateData(response?.data?.data);
      })
      .catch((error) => {});
  };

  const getCity = async (code) => {
    await getCityData(code)
      .then((response) => {
        setcityData(response?.data?.data);
      })
      .catch((error) => {});
  };

  const handeStateChange = (stateId) => {
    setFieldValue("state", stateId);
    let code;
    for (let a of stateData) {
      if (a?.id == Number(stateId)) {
        code = a?.state_code;
      }
    }
    getCity(code);
  };

  const AdminSchema = object().shape({
    email: string()
      .required("Please enter email.")
      .email("Invalid Email Format. Please try again.")
      .trim("Please enter email."),
    password: string()
      .required("Please enter password.")
      .trim("Please enter password.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        "Password must contain alphanumeric,uppercase,lowercase and special characters"
      ),
    confirmPassword: string()
      .required("Please enter confirm password") // .trim(t("Validation.ResetPassword.RequiredFiled") ?? "")
      .oneOf(
        [ref("password")],
        " Password and Confirm password does not match"
      ),
    firstName: string()
      .required("Please enter first name.")
      .trim("Please enter first name."),
    lastName: string()
      .required("Please enter last name.")
      .trim("Please enter last name."),

    address: string()
      .required("Please enter address.")
      .trim("Please enter address."),

    city: string().required("Please enter city.").trim("Please enter city."),
    state: string().required("Please enter state.").trim("Please enter state."),
    zip: string()
      .required("Please enter zip.")
      .trim("Please enter zip.")
      .matches(/^\d{5}$/, "Must be exactly 5 digits"),
    phone: string()
      .required("Please enter Mobile number.")
      .trim("Please enter Mobile number.")
      .test("phone", "Mobile number must be exactly 10 digits", (value) => {
        return validateNum(value);
      }),
    // .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits"),

    // isEmailSpeacialOffer: boolean().oneOf(
    //   [true],
    //   "You must accept the Newsletter."
    // ),
  });

  // const submitClicked = async (data) => {
  // setisDisable(true);

  // await register(data)
  //   .then((response) => {
  //     setisDisable(false);
  //     toast("Customer Register Successfully", { type: "success" });
  //     navigate("/login");
  //   })
  //   .catch((error) => {
  //     setisDisable(false);
  //     if (error?.response?.status == 422)
  //       toast(error?.response?.data?.message, { type: "error" });
  //     else if (error?.response?.status == 500)
  //       toast(error.response.data.message, { type: "error" });
  //     else
  //       toast(error?.response?.data?.message || "Something went wrong", {
  //         type: "error",
  //       });
  //   });
  // };

  const {
    handleSubmit,
    handleBlur,
    setFieldValue,
    handleChange,
    values,
    errors,
    touched,
  } = useFormik({
    validationSchema: AdminSchema,

    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      company: "",
      address: "",
      password: "",
      confirmPassword: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      logo: "",
      isEmailSpeacialOffer: true,
      dateOfBirth: "",
      code: "",
    },
    onSubmit: async () => {
      // if (!isVerified) {
      //   toast("You must verify your email.", {
      //     type: "error",
      //   });
      // }
      if (values?.code !== Number(securityCode)) {
        toast("Security code does not match.", {
          type: "error",
        });
      } else {
        // let formdata = new FormData();
        // formdata.append("firstName", values.firstName);
        // formdata.append("lastName", values.lastName);
        // formdata.append("email", values.email);
        // // formdata.append("password", values.password);
        // formdata.append("company", values.company);
        // formdata.append("address", values.address);
        // formdata.append("address2", values.address2);
        // formdata.append("city", values.city);
        // formdata.append("state", values.state);
        // formdata.append("zip", values.zip);
        // formdata.append("phone", values.phone);
        // formdata.append("fax", values.fax);
        // formdata.append("dateOfBirth", dateSendToBackend(values.dateOfBirth));
        // formdata.append("logo", values.logo);
        // formdata.append("isEmailSpeacialOffer", values.isEmailSpeacialOffer);
        // submitClicked(formdata);
        verifyEmail();
      }
    },

    onReset: () => {},
  });

  // useEffect(() => {
  //   if (!_.isEmpty(values?.email)) {
  //     if (values?.email === verifiedEmail) {
  //       setisVerified(true);
  //     } else {
  //       setisVerified(false);
  //     }
  //   }
  // }, [values?.email]);

  const verifyEmail = async () => {
    setisDisable(true);
    let formdata = new FormData();
    formdata.append("email", values?.email);
    await verifyEmailByOTP(formdata)
      .then((response) => {
        setisDisable(false);
        // setModalIsOpen(true);
        toast("OTP successfully sent to your email", { type: "success" });
        navigate("/otp-verification", { state: values });
      })
      .catch((error) => {
        setisDisable(false);

        if (error?.response?.status == 422)
          toast(error?.response?.data?.message, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else
          toast(error?.response?.data?.message || "Something went wrong", {
            type: "error",
          });
      });
  };

  // const verifyOTP = async (data) => {
  //   let formdata = new FormData();
  //   formdata.append("email", values?.email);
  //   formdata.append("otp", data);

  //   await verifyEmailOTP(formdata)
  //     .then((response) => {
  //       setModalIsOpen(false);
  //       setisVerified(true);
  //       setverifiedEmail(values?.email);
  //       toast("OTP Verified Successfully", { type: "success" });
  //     })
  //     .catch((error) => {
  //       if (error?.response?.status == 422)
  //         toast(error?.response?.data?.message, { type: "error" });
  //       else if (error?.response?.status == 500)
  //         toast(error.response.data.message, { type: "error" });
  //       else
  //         toast(error?.response?.data?.message || "Something went wrong", {
  //           type: "error",
  //         });
  //     });
  // };

    const removeImage = (name) => {
      setFieldValue(name, null);
      fileInputRef.current.value = "";
      setselectedImage(null);
    };
    
  const handleImageChange = (event) => {
    const file = event?.target?.files[0];
    setFieldValue("logo", file);
    setselectedImage(URL.createObjectURL(file));

    const reader = new FileReader();
    reader.onloadend = () => {
      //  setImagePreview(reader?.result);
    };
    reader.readAsDataURL(file);
  };


  
  const handleChangeMobile = (num) => {
    setFieldValue("phone", formateMobileNumber(num));
  };

  return (
    <div
      style={{ display: "flex", justifyContent: "center", paddingTop: "140px" }}
    >
      <div className="binduz-er-contact-us-area ">
        <div className=" container">
          <div className="row" style={{ justifyContent: "center" }}>
            <div className=" col-xl-12 col-lg-12">
              <div
                className="binduz-er-contact-us-box box-shadow"
                style={{ padding: "0px 60px 60px" }}
              >
                <form onSubmit={((e) => e?.preventDefault(), handleSubmit)}>
                  <div
                    className="binduz-er-contact-title"
                    style={{ paddingTop: "15px" }}
                  >
                    <h1
                      className="binduz-er-title"
                      style={{ textAlign: "center", fontSize: "30px" }}
                    >
                      REGISTER
                    </h1>
                  </div>
                  <div
                    className="row"
                    style={{
                      paddingTop: "15px",
                      paddingBottom: "15px",
                      // gap: "20px",
                    }}
                  >
                    {/* <div className="col-xl-3 col-lg-4 col-md-6">
                      <div className="binduz-er-input-box">
                        <div className="input-button-wrapper">
                          <input
                            type="text"
                            placeholder="Email *"
                            name="email"
                            id="email"
                            value={values.email}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            style={{
                              borderColor:
                                touched?.email && errors?.email && "red",
                              paddingRight: "0px",
                            }}
                          />

                          {isVerified ? (
                            <i
                              className="fal fa-check"
                              style={{ color: "green" }}
                            ></i>
                          ) : (
                            <button
                              type="button"
                              className="send-otp-btn"
                              onClick={() => {
                                if (values?.email && !errors?.email) {
                                  verifyEmail();
                                }
                              }}
                              style={{ fontSize: "12px" }}
                            >
                              {!otpLoading ? "Send OTP" : "Loading..."}
                            </button>
                          )}
                        </div>
                      </div>
                      {touched?.email && errors?.email && (
                        <span className="text-danger">{errors.email}</span>
                      )}
                    </div> */}

                    <div className="col-xl-3 col-lg-4 col-md-6">
                      <div className="binduz-er-input-box">
                        <input
                          type="text"
                          placeholder="Email *"
                          name="email"
                          id="email"
                          value={values.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          style={{
                            borderColor:
                              touched?.email && errors?.email && "red",
                          }}
                        />
                      </div>
                      {touched?.email && errors?.email && (
                        <span className="text-danger">{errors.email}</span>
                      )}
                    </div>

                    <div className="col-xl-3 col-lg-4 col-md-6">
                      <div className="binduz-er-input-box">
                        <input
                          type="text"
                          placeholder="First Name *"
                          name="firstName"
                          id="firstName"
                          value={values.firstName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          style={{
                            borderColor:
                              touched?.firstName && errors?.firstName && "red",
                          }}
                        />
                      </div>
                      {touched?.firstName && errors?.firstName && (
                        <span className="text-danger">{errors.firstName}</span>
                      )}
                    </div>

                    <div className="col-xl-3 col-lg-4 col-md-6">
                      <div className="binduz-er-input-box">
                        <input
                          type="text"
                          placeholder="Last Name *"
                          name="lastName"
                          id="lastName"
                          value={values.lastName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          style={{
                            borderColor:
                              touched?.lastName && errors?.lastName && "red",
                          }}
                        />
                        {/* <i className="fal fa-user"></i> */}
                      </div>
                      {touched?.lastName && errors?.lastName && (
                        <span className="text-danger">{errors.lastName}</span>
                      )}
                    </div>

                    <div className="col-xl-3 col-lg-4 col-md-6">
                      <div className="binduz-er-input-box">
                        <input
                          type="text"
                          placeholder="Company "
                          name="company"
                          id="company"
                          value={values.company}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          style={{
                            borderColor:
                              touched?.company && errors?.company && "red",
                          }}
                        />
                      </div>
                      {touched?.company && errors?.company && (
                        <span className="text-danger">{errors.company}</span>
                      )}
                    </div>

                    <div className="col-xl-3 col-lg-4 col-md-6">
                      <div className="binduz-er-input-box">
                        <input
                          type={isPassword ? "text" : "password"}
                          placeholder="Password *"
                          name="password"
                          id="password"
                          value={values.password}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          style={{
                            borderColor:
                              touched?.password && errors?.password && "red",
                          }}
                        />
                        {isPassword ? (
                          <i
                            className="fal fa-eye"
                            onClick={() => setisPassword(false)}
                            style={{ cursor: "pointer" }}
                          />
                        ) : (
                          <i
                            className="fal fa-eye-slash"
                            style={{ cursor: "pointer" }}
                            onClick={() => setisPassword(true)}
                          />
                        )}
                      </div>
                      {touched?.password && errors?.password && (
                        <span className="text-danger">{errors.password}</span>
                      )}{" "}
                    </div>

                    <div className="col-xl-3 col-lg-4 col-md-6">
                      <div className="binduz-er-input-box">
                        <input
                          type={isConfirmPassword ? "text" : "password"}
                          placeholder="Confirm Password *"
                          name="confirmPassword"
                          id="confirmPassword"
                          value={values.confirmPassword}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          style={{
                            borderColor:
                              touched?.confirmPassword &&
                              errors?.confirmPassword &&
                              "red",
                          }}
                        />
                        {isConfirmPassword ? (
                          <i
                            className="fal fa-eye"
                            onClick={() => setisConfirmPassword(false)}
                            style={{ cursor: "pointer" }}
                          />
                        ) : (
                          <i
                            className="fal fa-eye-slash"
                            style={{ cursor: "pointer" }}
                            onClick={() => setisConfirmPassword(true)}
                          />
                        )}
                      </div>
                      {touched?.confirmPassword && errors?.confirmPassword && (
                        <span className="text-danger">
                          {errors.confirmPassword}
                        </span>
                      )}{" "}
                    </div>

                    <div className="col-xl-3 col-lg-4 col-md-6">
                      <div className="binduz-er-input-box">
                        <input
                          type="text"
                          placeholder="Address *"
                          name="address"
                          id="address"
                          value={values.address}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          style={{
                            borderColor:
                              touched?.address && errors?.address && "red",
                          }}
                        />
                      </div>
                      {touched?.address && errors?.address && (
                        <span className="text-danger">{errors.address}</span>
                      )}{" "}
                    </div>

                    <div className="col-xl-3 col-lg-4 col-md-6">
                      <div className="binduz-er-input-box">
                        <input
                          type="text"
                          placeholder="Landmark"
                          name="address2"
                          id="address2"
                          value={values.address2}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          style={{
                            borderColor:
                              touched?.address2 && errors?.address2 && "red",
                          }}
                        />
                      </div>
                      {touched?.address2 && errors?.address2 && (
                        <span className="text-danger">{errors.address2}</span>
                      )}
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6">
                      <div className="binduz-er-input-box ">
                        <select
                          placeholder="Data"
                          name="state"
                          id="state"
                          value={values.state}
                          onBlur={handleBlur}
                          // onChange={handleChange}
                          onChange={(e) => handeStateChange(e?.target?.value)}
                          style={{
                            borderColor:
                              touched?.state && errors?.state && "red",
                          }}
                        >
                          <option value="">State *</option>
                          {stateData?.map((x) => (
                            <option value={x?.id}>{x?.state}</option>
                          ))}
                        </select>
                      </div>
                      {touched?.state && errors?.state && (
                        <span className="text-danger">{errors?.state}</span>
                      )}
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6">
                      <div className="binduz-er-input-box">
                        <select
                          placeholder="City"
                          name="city"
                          id="city"
                          value={values.city}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          style={{
                            borderColor: touched?.city && errors?.city && "red",
                          }}
                        >
                          <option value="">City *</option>
                          {cityData?.map((x) => (
                            <option value={x?.id}>{x?.city}</option>
                          ))}
                        </select>
                      </div>
                      {touched?.city && errors?.city && (
                        <span className="text-danger">{errors?.city}</span>
                      )}
                    </div>

                    <div className="col-xl-3 col-lg-4 col-md-6">
                      <div className="binduz-er-input-box">
                        <input
                          type="number"
                          placeholder="Zip *"
                          name="zip"
                          id="zip"
                          value={values.zip}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          style={{
                            borderColor: touched?.zip && errors?.zip && "red",
                          }}
                        />
                      </div>
                      {touched?.zip && errors?.zip && (
                        <span className="text-danger">{errors.zip}</span>
                      )}
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6">
                      <div className="binduz-er-input-box">
                        <input
                          // type="number"
                          type="text"
                          placeholder="Mobile Number *"
                          name="phone"
                          id="phone"
                          value={values.phone}
                          onBlur={handleBlur}
                          onChange={(e) => handleChangeMobile(e?.target?.value)}
                          // onChange={handleChange}
                          style={{
                            borderColor:
                              touched?.phone && errors?.phone && "red",
                          }}
                        />
                      </div>
                      {touched?.phone && errors?.phone && (
                        <span className="text-danger">{errors.phone}</span>
                      )}
                    </div>

                    <div className="col-xl-3 col-lg-4 col-md-6 ">
                      <div className="binduz-er-input-box">
                        <ReactDatePicker
                          placeholderText="Date Of Birth"
                          value={values?.dateOfBirth}
                          autoComplete="off"
                          maxDate={new Date()}
                          onChange={(e) => {
                            setFieldValue(
                              "dateOfBirth",
                              formatDateToMMDDYYYY(new Date(e))
                            );
                          }}
                          name="dateOfBirth"
                          style={{
                            borderColor:
                              touched?.dateOfBirth &&
                              errors?.dateOfBirth &&
                              "red",
                          }}
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={100} // Show
                          showMonthDropdown
                        />
                      </div>
                      {touched?.dateOfBirth && errors?.dateOfBirth && (
                        <span className="text-danger">
                          {errors.dateOfBirth}
                        </span>
                      )}
                    </div>

                    <div className="col-lg-3">
                      <div className="binduz-er-input-box">
                        <label htmlFor="logo" className="custom-file-label">
                          Choose Image
                        </label>
                        <input
                          className="form-control custom-file-input"
                          type="file"
                          accept="image/*"
                          name="logo"
                          ref={fileInputRef}
                          id="logo"
                          onBlur={handleBlur}
                          onChange={(e) => handleImageChange(e)}
                        />
                      </div>
                      {selectedImage && (
                        <>
                          <a
                            href={selectedImage}
                            target="_blank"
                            className="m-1"
                          >
                            <i
                              className="fal fa-eye"
                              style={{ cursor: "pointer" }}
                            />
                          </a>
                          <a
                            className="m-1"
                            onClick={() => removeImage("logo")}
                          >
                            <i
                              className="fal fa-trash"
                              style={{ cursor: "pointer" }}
                            />
                          </a>
                        </>
                      )}
                      {/* <span>( Max Width/Height: 1150px * 650px )</span> */}
                    </div>

                    <div>
                      <input
                        className="mt-4"
                        style={{ marginRight: "7px" }}
                        type="checkbox"
                        id="isEmailSpeacialOffer"
                        name="isEmailSpeacialOffer"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        checked={values?.isEmailSpeacialOffer}
                      />
                      <span>
                        Receive Emails Monthly Newsletter & special offers
                      </span>
                    </div>
                    {touched?.isEmailSpeacialOffer &&
                      errors?.isEmailSpeacialOffer && (
                        <span className="text-danger">
                          {errors.isEmailSpeacialOffer}
                        </span>
                      )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "30px",
                      }}
                    >
                      <div
                        className=" col-xl-1 col-lg-2 col-md-2"
                        style={{ minWidth: "130px" }}
                      >
                        <div className="binduz-er-input-box">
                          <input
                            type="text"
                            placeholder="Code"
                            style={{
                              fontSize: "27px",
                              paddingLeft: "7px",
                              borderRadius: "10px",
                              textAlign: "center",
                              color: "#FF6821",
                            }}
                            value={securityCode}
                          />
                          {/* <i className="fal fa-user"></i> */}
                        </div>
                      </div>
                      <div className=" col-xl-2 col-lg-4 col-md-4">
                        <div className="binduz-er-input-box">
                          <input
                            type="number"
                            placeholder="Security Code"
                            min={0}
                            id="code"
                            name="code"
                            value={values?.code}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div
                      className=" col-lg-12"
                      style={{ textAlign: "center", paddingBottom: "25px" }}
                    >
                      <div>
                        <a>
                          <i
                            className="fab fa-facebook-f login-fb"
                            style={{ marginRight: "15px" }}
                          ></i>
                        </a>
                        <a>
                          <i className="fab fa-google login-google"></i>
                        </a>
                      </div>
                    </div> */}
                  </div>
                  <button
                    type="submit"
                    disabled={isDisable}
                    onClick={handleSubmit}
                    className="binduz-er-main-btn contact-us-box-button mt-5"
                  >
                    {isDisable ? "Loading..." : "Submit"}
                  </button>
                </form>
                {/* <OTPpopUp
                  modalIsOpen={modalIsOpen}
                  setModalIsOpen={setModalIsOpen}
                  yesClicked={verifyOTP}
                  verifyEmail={verifyEmail}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainSection;
