import React, { useEffect, useState } from "react";
import img from "../../assets/images/azlogomessage.jpeg";
import dr_photo_01 from "../../assets/images/dr_photo_01.png";
import dr_photo_02 from "../../assets/images/dr_photo_02.png";
import dr_photo_03 from "../../assets/images/dr_photo_03.png";
import dr_photo_04 from "../../assets/images/dr_photo_04.png";
import dr_photo_05 from "../../assets/images/dr_photo_05.png";
import dr_photo_06 from "../../assets/images/dr_photo_06.png";
import dr_photo_07 from "../../assets/images/dr_photo_07.png";
import dr_photo_08 from "../../assets/images/dr_photo_07.png";
import dr_photo_09 from "../../assets/images/dr_photo_07.png";
import { getPremiumDoctorData } from "../../services/HomeServices";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../containers/Loader";

const DoctorDesign = () => {
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getDocData();
  }, []);

  const getDocData = async (pageNumber) => {
    setisLoading(true);

    await getPremiumDoctorData(pageNumber, 10)
      .then((response) => {
        let resData = response?.data?.data?.data;
        setdata(resData);
        setisLoading(false);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };
  return (
    <div className="container-fluid bg_white">
      <div className="container doctors_main">
        <div className="row">
          <div className="col-6">
            <h2>Doctors</h2>
          </div>
          <div className="col-6">
            {" "}
            <Link to="/doctor-post" className="btn_post">
              Post <i className="fas fa-plus" />
            </Link>{" "}
          </div>
        </div>
        {!isLoading ? (
          <div className="row pt-4">
            <div className="col-sm-4">
              <div
                className="doctor_photo"
                onClick={() =>
                  navigate(`/doctor/${data[0]?.id}`, { state: data[0] })
                }
              >
                <h5>
                  {data[0]?.name} <span>{data[0]?.category?.name}</span>
                </h5>
                <img
                  src={data[0]?.profile_image || img}
                  alt="photo"
                  style={{ height: "375px", width: "375px" }}
                />
              </div>
            </div>
            <div className="col-sm-8 ods_pd0">
              <div className="row">
                {data?.slice(1, 9)?.map((x) => (
                  <div
                    className="col-sm-3"
                    onClick={(e) => {
                      e?.preventDefault();
                      navigate(`/doctor/${x?.id}`, { state: x });
                    }}
                  >
                    <a href="#" className="doctor_photo">
                      <h5>
                        {x?.name} <span>{x?.category?.name}</span>
                      </h5>
                      <img
                        style={{ height: "180px", width: "180px" }}
                        src={x?.profile_image || img}
                      />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
      <Link to="/doctor" className="btn_seeall">
        See All <i className="fas fa-chevron-right" />
      </Link>{" "}
    </div>
  );
};

export default DoctorDesign;
