import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

const getAZTimesData = async (
  search = "",
  duration = "",
  page = 1,
  perPage = 10
) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(
        `/azTimes?search=${search}&month=${duration}&page=${page}&per_page=${perPage}`
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        // handleAuthFailure(error, reject);
      });
  });
};

const getTopStoriesData = async (
  search = "",
  duration = "",
  page = 1,
  perPage = 10
) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(
        `/top-story?search=${search}&month=${duration}&page=${page}&per_page=${perPage}`
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        // handleAuthFailure(error, reject);
      });
  });
};

const getMsgData = async (
  search = "",
  duration = "",
  page = 1,
  perPage = 10
) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(
        `/messages?search=${search}&month=${duration}&page=${page}&per_page=${perPage}`
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        // handleAuthFailure(error, reject);
      });
  });
};

const getLocalBussinessData = async (search = "", page, per_page = 10) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(`/business-list?page=${page}&search=${search}&per_page=${per_page}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        // handleAuthFailure(error, reject);
      });
  });
};

const getbussinessCategoryList = async (search) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(`/business-category?search=${search}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        // handleAuthFailure(error, reject);
      });
  });
};

const getbussinessDataByCategory = async (
  id,
  page = 1,
  search = "",
  perPage = 10
) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(
        `/category-business-list?category_id=${id}&page=${page}&search=${search}&per_page=${perPage}`
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        // handleAuthFailure(error, reject);
      });
  });
};

const getDoctorList = async (search) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(`/doctor-category?search=${search}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        // handleAuthFailure(error, reject);
      });
  });
};

const getClassifieldCatData = async () => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(`/classified-category`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        // handleAuthFailure(error, reject);
      });
  });
};

const getClassifieldPremiumData = async (page = 1, perPage = 10) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(`/premium-classified?page=${page}&per_page=${perPage}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        // handleAuthFailure(error, reject);
      });
  });
};

const getJobsSubCatData = async () => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(`/job-subcategory`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        // handleAuthFailure(error, reject);
      });
  });
};

const getClassifieldSubCatData = async (id) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(`/classified-sub-category?category_id=${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        // handleAuthFailure(error, reject);
      });
  });
};

const getOrganizationData = async (search) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(`/organization?search=${search}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        // handleAuthFailure(error, reject);
      });
  });
};

const getLatestClassifieldData = async () => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(`/latest-classified`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        // handleAuthFailure(error, reject);
      });
  });
};

const getClassifieldData = async (
  id = "",
  search = "",
  month = "",
  page = "",
  per_page = 10
) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(
        `/category-classified-list?category_id=${id}&month=${month}&search=${search}&page=${page}&per_page=${per_page}`
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        // handleAuthFailure(error, reject);
      });
  });
};

const getJobsData = async (
  search = "",
  month = "",
  page = 1,
  category_id = "",
  per_page = 10
) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(
        `/jobs?category_id=${category_id}&month=${month}&search=${search}&page=${page}&per_page=${per_page}`
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        // handleAuthFailure(error, reject);
      });
  });
};

const getReligiousPlacesData = async (search = "", page = 1, perPage = 10) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(`/religious-place?search=${search}&page=${page}&per_page=${perPage}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        // handleAuthFailure(error, reject);
      });
  });
};

const getMegaPhoneData = async (search = "", page, per_page = 10) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(`/megaphone?page=${page}&search=${search}&per_page=${per_page}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        // handleAuthFailure(error, reject);
      });
  });
};

const getDoctorData = async (id, search = "", page, per_page = 10) => {
  let token = await localStorage.getItem("token");

  return new Promise(function (resolve, reject) {
    axios
      .get(
        `/category-doctor-list?category_id=${id}&search=${search}&page=${page}&per_page=${per_page}`
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        // handleAuthFailure(error, reject);
      });
  });
};

const getPremiumDoctorData = async (page, per_page = 10) => {
  let token = await localStorage.getItem("token");

  return new Promise(function (resolve, reject) {
    axios
      .get(`/premium-doctor?page=${page}&per_page=${per_page}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        // handleAuthFailure(error, reject);
      });
  });
};

const globalSearch = async (search) => {
  let token = await localStorage.getItem("token");

  return new Promise(function (resolve, reject) {
    axios
      .get(`/global-search?search=${search}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        // handleAuthFailure(error, reject);
      });
  });
};

const getCatDetail = async (id = "", month = "", date = "", page = 1) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(`/event?category_id=${id}&month=${month}&date=${date}&page=${page}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        // handleAuthFailure(error, reject);
      });
  });
};

const getPremiumEvent = async (id = "", month = "", date = "") => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(`/premium-event?category_id=${id}&month=${month}&date=${date}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        // handleAuthFailure(error, reject);
      });
  });
};

const getEventCatData = async () => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(`/event-category`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        // handleAuthFailure(error, reject);
      });
  });
};

const getPremiumAmount = async () => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(`/payment-amount`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        handleAuthFailure(error, reject);
      });
  });
};

const getLocation = async (id) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(`/event-locations?type_id=${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        // handleAuthFailure(error, reject);
      });
  });
};

const getEventDetail = async (location_id, id) => {
  let token = await localStorage.getItem("token");

  return new Promise(function (resolve, reject) {
    axios
      .get(`/event-detail?location_id=${location_id}&event_id=${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        // handleAuthFailure(error, reject);
      });
  });
};

const getDocotrDetail = async (id) => {
  let token = await localStorage.getItem("token");

  return new Promise(function (resolve, reject) {
    axios
      .get(`/doctor-detail?id=${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        // handleAuthFailure(error, reject);
      });
  });
};

const getClassifieldDetail = async (id) => {
  let token = await localStorage.getItem("token");

  return new Promise(function (resolve, reject) {
    axios
      .get(`/classified-detail?id=${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        // handleAuthFailure(error, reject);
      });
  });
};

const getAboutData = async () => {
  let token = await localStorage.getItem("token");

  return new Promise(function (resolve, reject) {
    axios
      .get(`/about`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        // handleAuthFailure(error, reject);
      });
  });
};

const getThingsToDo = async () => {
  let token = await localStorage.getItem("token");

  return new Promise(function (resolve, reject) {
    axios
      .get(`/things_todo`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        // handleAuthFailure(error, reject);
      });
  });
};

const getBannerIMG = async () => {
  let token = await localStorage.getItem("token");

  return new Promise(function (resolve, reject) {
    axios
      .get(`/main-banner`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        // handleAuthFailure(error, reject);
      });
  });
};

const getPodcast = async () => {
  let token = await localStorage.getItem("token");

  return new Promise(function (resolve, reject) {
    axios
      .get(`/podcast`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        // handleAuthFailure(error, reject);
      });
  });
};

const getSponsorIMG = async () => {
  let token = await localStorage.getItem("token");

  return new Promise(function (resolve, reject) {
    axios
      .get(`/banner`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        // handleAuthFailure(error, reject);
      });
  });
};

const getPlaceToVisit = async () => {
  let token = await localStorage.getItem("token");

  return new Promise(function (resolve, reject) {
    axios
      .get(`/place_to_visit`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        // handleAuthFailure(error, reject);
      });
  });
};

function handleAuthFailure(error, reject) {
  if (
    error?.response.status === 401 &&
    error?.response.data.message === "Unauthenticated."
  ) {
    localStorage.removeItem("token");
    localStorage.removeItem("is_authenticated");

    if (window.location.pathname != "/login") {
      window.location.href = "/#/login";
    }
  } else {
    reject(error);
  }
}

export {
  getAZTimesData,
  getbussinessCategoryList,
  getMegaPhoneData,
  getLocalBussinessData,
  getMsgData,
  getCatDetail,
  getPremiumAmount,
  getAboutData,
  getReligiousPlacesData,
  getEventCatData,
  getbussinessDataByCategory,
  getLocation,
  getPremiumDoctorData,
  getDoctorData,
  getClassifieldPremiumData,
  getThingsToDo,
  getPodcast,
  getDoctorList,
  getOrganizationData,
  getPremiumEvent,
  globalSearch,
  getBannerIMG,
  getJobsSubCatData,
  getSponsorIMG,
  getJobsData,
  getPlaceToVisit,
  getClassifieldSubCatData,
  getDocotrDetail,
  getClassifieldDetail,
  getClassifieldData,
  getEventDetail,
  getClassifieldCatData,
  getTopStoriesData,
  getLatestClassifieldData,
};
