import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getbussinessCategoryList,
  getbussinessDataByCategory,
  getLocalBussinessData,
} from "../../services/HomeServices";
import Loader from "../../containers/Loader";
import { formatDate } from "../../lib/constant";
import img from "../../assets/images/azlogomessage.jpeg";
import _ from "lodash";
import BussinessDetails from "./BussinessDetails";
import Pagination from "../../containers/Pagination";

const NewDesign = () => {
  const navigate = useNavigate();
  const [toggle, settoggle] = useState(false);
  const [selectedBussiness, setselectedBussiness] = useState({});
  const [data, setdata] = useState([]);
  const [catSearch, setcatSearch] = useState("");
  const [catIsLoading, setcatIsLoading] = useState(false);
  const [bussinessCategoryList, setbussinessCategoryList] = useState([]);
  const [search, setsearch] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [page, setpage] = useState(1);
  const [catId, setcatId] = useState();

  const [links, setlinks] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
    if (!catId) {
      setTimeout(() => {
        getData();
      }, 500);
    }
  }, [page]);

  useEffect(() => {
    if (!catId) {
      setTimeout(() => {
        setpage(1);
        getData();
      }, 500);
    }
  }, [search]);

  useEffect(() => {
    setTimeout(() => {
      getCategory();
    }, 1000);
  }, [catSearch]);

  useEffect(() => {
    if (catId) {
      setTimeout(() => {
        getDataByCategory();
      }, 1000);
    }
  }, [page]);

  useEffect(() => {
    if (catId) {
      setTimeout(() => {
        setpage(1);
        getDataByCategory();
      }, 1000);
    }
  }, [search, catId]);

  const getData = async () => {
    setisLoading(true);
    await getLocalBussinessData(search?.trim(), page)
      .then((response) => {
        setisLoading(false);
        setdata(response?.data?.data?.data);
        setlinks(response?.data?.data?.links);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  const getCategory = async () => {
    setcatIsLoading(true);
    await getbussinessCategoryList(catSearch?.trim())
      .then((response) => {
        setcatIsLoading(false);
        setbussinessCategoryList(response?.data?.data);
      })
      .catch((error) => {
        setcatIsLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  const getDataByCategory = async () => {
    setisLoading(true);
    await getbussinessDataByCategory(catId, page, search)
      .then((response) => {
        setisLoading(false);
        setdata(response?.data?.data?.data);
        setlinks(response?.data?.data?.links);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <div className="container-fluid bg_white pt-5">
      <div className="container">
        <div className="row">
          <div className="col-sm-4">
            <div className="doct_categ">
              <h2>Categories</h2>
              <hr />
              <div className="search_box_02">
                {" "}
                <i className="fa fa-search" aria-hidden="true" />
                <input
                  type="text"
                  name="search"
                  value={catSearch}
                  onChange={(e) => {
                    setcatSearch(e?.target?.value);
                    settoggle(false);
                  }}
                  placeholder="Search category"
                />
              </div>
              <hr />
              <div className="dc_scroll">
                {!catIsLoading ? (
                  <>
                    {bussinessCategoryList?.map((x) => (
                      <Link
                        onClick={() => {
                          setcatId(x?.id);
                          settoggle(false);
                        }}
                      >
                        {x?.name} <span>{x?.businesses_count || 0}</span>
                      </Link>
                    ))}
                  </>
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </div>
          <div className="col-sm-8">
            {!toggle ? (
              <>
                <div className="row doct_main">
                  <div className="col-sm-6">
                    <h2>Local Businesses</h2>
                  </div>
                  <div className="col-sm-6">
                    {" "}
                    <Link to="/local-bussiness-post" className="btn_post">
                      Post <i className="fas fa-plus" />
                    </Link>{" "}
                  </div>
                  <div className="col-sm-12 pt-3">
                    <p>
                      Our community website helps connect local businesses with
                      residents by offering a platform for businesses to
                      showcase their services and promotions. It creates a
                      seamless link between businesses and potential customers,
                      making it easy for residents to discover services and for
                      businesses to reach a wider audience of seekers.
                    </p>
                  </div>
                  <div className="col-sm-12">
                    <form
                      className="s_box"
                      onSubmit={(e) => {
                        e?.preventDefault();
                        getData();
                      }}
                    >
                      <i className="fa fa-search" aria-hidden="true" />
                      <input
                        type="text"
                        name="search"
                        placeholder="Search"
                        onChange={(e) => setsearch(e?.target?.value)}
                        value={search}
                      />
                    </form>
                  </div>
                </div>
                <div className="row pt-4">
                  {!isLoading ? (
                    <>
                      {!_.isEmpty(data) ? (
                        <>
                          {data?.map((item) => (
                            <div
                              className="col-sm-4"
                              onClick={() => {
                                setselectedBussiness(item);
                                settoggle(true);
                              }}
                            >
                              <div className="business_card ">
                                <img
                                  className="card-img-top"
                                  src={item?.image || img}
                                  style={{
                                    height: "19vh",
                                    width: "100%",
                                    objectFit: "cover",
                                  }}
                                  alt="business"
                                />
                                <b>{item?.name}</b>
                                <label>
                                  <i className="far fa-calendar" />{" "}
                                  {formatDate(item?.start_date)}
                                </label>
                                <p
                                  className="line-clump-2"
                                  style={{ cursor: "default", height: "80px" }}
                                  dangerouslySetInnerHTML={{
                                    __html: item?.description,
                                  }}
                                />
                                <div className="row">
                                  <div className="col-sm-12">
                                    <a>More</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                          <Pagination links={links} setpage={setpage} />
                        </>
                      ) : (
                        <h5 style={{ textAlign: "center" }}>No Data found</h5>
                      )}
                    </>
                  ) : (
                    <div style={{ marginTop: "80px" }}>
                      <Loader />
                    </div>
                  )}
                </div>
              </>
            ) : (
              <BussinessDetails data={selectedBussiness} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewDesign;
