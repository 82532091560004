import React, { useEffect, useState } from "react";
import { formatDate, formatDateToMMDDYYYY } from "../../lib/constant";
import iconTime from "../../assets/images/icon/icon_date_time.svg";
import iconLocation from "../../assets/images/icon/icon_location.svg";
import iconorganized from "../../assets/images/icon/icon_organized.svg";
import ClassifiedDesign from "../NewDesign/ClassifiedDesign";
import EventDesign from "../NewDesign/EventDesign";

const NewEventDetailPage = ({ data }) => {
  const [isEmptyVideoArray, setisEmptyVideoArray] = useState(true);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  }, []);

  useEffect(() => {
    if (data?.event?.video?.some((a) => a?.link)) {
      setisEmptyVideoArray(false);
    }
  }, []);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "My Website",
          text: "Check out this awesome content!",
          url: window.location.href,
        });
        console.log("Content shared successfully");
      } catch (err) {
        console.error("Error sharing the content:", err);
      }
    } else {
      alert("Sharing is not supported on this browser.");
    }
  };
  const formatDateForEvent = (date) => {
    const optionsTime = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };

    const formattedTime = date?.toLocaleTimeString("en-US", optionsTime);

    return `${formatDateToMMDDYYYY(date)} ${formattedTime}`;
  };

  let fullAddress = `${data?.location?.name}, ${data?.location?.address}, ${data?.location?.city?.city} ,${data?.location?.state?.state} ,${data?.location?.zipcode}`;
  let organizedByName =
    data?.event?.association?.company_name || data?.event?.user?.full_name;

  return (
    <>
      <div className="container-fluid event_inner_banner">
        <div className="container">
          <div className="row">
            <div className="col-sm-5">
              {data?.event?.flyer && (
                <img src={data?.event?.flyer} alt="image" />
              )}
              ;
            </div>
            <div className="col-sm-7">
              <h1>{data?.event?.name}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg_gray">
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <a className="cd_detales cd_tab1">
                <img src={iconorganized} alt="icon" />
                <label className="cd_mtb">
                  <b>Organized By</b>
                  {organizedByName}{" "}
                </label>
              </a>
            </div>
            <div className="col-sm-4">
              <a className="cd_detales cd_tab2">
                <img src={iconTime} alt="icon" />
                <label className="cd_mtb">
                  <b>Date &amp; Time</b>
                  {formatDate(data?.event?.location[0]?.date)}
                  &nbsp;&nbsp;
                  {data?.event?.location[0]?.time}
                </label>
              </a>
            </div>
            <div className="col-sm-4">
              <a className="cd_detales cd_tab3">
                <img src={iconLocation} alt="icon" />
                <label>
                  <b>Venue</b>
                  {fullAddress}
                </label>
              </a>
            </div>
            <div className="col-sm-12 pt-3">
              <div className="ed_box">
                <div className="row">
                  <div
                    className="col-sm-9"
                    style={{ padding: "25px", paddingRight: "50px" }}
                  >
                    <h4>{data?.event?.name}</h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data?.event?.description,
                      }}
                    />
                    {data?.event?.image && (
                      <>
                        <p className="mt-3">
                          <b>Organized By:</b>
                        </p>
                        <p>
                          <a>
                            <img src={data?.event?.image} alt="logo" />
                          </a>
                        </p>
                      </>
                    )}
                    {data?.event?.sponsor_logo && (
                      <>
                        <p className="mt-3">
                          <b>Sponsored by:</b>
                        </p>

                        <p>
                          <a>
                            <img src={data?.event?.sponsor_logo} alt="logo" />
                          </a>
                        </p>
                      </>
                    )}
                  </div>
                  {data?.event?.flyer && (
                    <div className="col-sm-3">
                      <img
                        src={data?.event?.flyer}
                        alt="image"
                        className="ed_rimg"
                      />
                    </div>
                  )}
                </div>
              </div>
              <a onClick={handleShare} className="btn_share">
                <i className="fas fa-share-alt" /> Share
              </a>
            </div>
          </div>
        </div>
      </div>
      <ClassifiedDesign onlyClassified={true} />
      <EventDesign />
    </>
  );
};

export default NewEventDetailPage;
