import React, { useEffect, useState } from "react";
import img from "../../assets/images/azlogomessage.jpeg";
import { Link, useNavigate } from "react-router-dom";
import { getPodcast } from "../../services/HomeServices";
import { toast } from "react-toastify";
import {
  formatDateToMMDDYYYY,
  minimizeName,
  plainString,
} from "../../lib/constant";

const PodcastDesign = () => {
  const [isLoading, setisLoading] = useState(false);
  const [data, setdata] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getPodcastData();
  }, []);

  const getPodcastData = async () => {
    setisLoading(true);
    await getPodcast()
      .then((response) => {
        setisLoading(false);
        setdata(response?.data?.data?.data);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };


  const embedLink = (url) => {
    const videoId = url?.split("/").pop().split("?")[0];

    // Construct the embedded link
    const embeddedLink = `https://www.youtube.com/embed/${videoId}`;
    return embeddedLink;
  };
  return (
    <div className="container-fluid bg_gray">
      <div className="podcast_main">
        <h2>Trending podcast</h2>
        <div className="pt-4">
          <div className="podcast_scroll">
            {data?.map((x) => (
              <div className="col-sm-3">
                <div className="podcast_card">
                  <iframe
                    src={embedLink(x?.link)}
                    title="YouTube video player"
                    frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  />
                  <label>{minimizeName(x?.category)}</label>
                  <span>
                    <i className="far fa-calendar" />
                    {formatDateToMMDDYYYY(new Date(x?.created_at))}
                  </span>
                  <h6 className="p-2">{x?.title}</h6>
                  <p>{minimizeName(plainString(x?.description),120)}</p>
                </div>
              </div>
            ))}
          </div>
          <Link to="/podcast" className="btn_seeall">
            See All <i className="fas fa-chevron-right" />
          </Link>{" "}
        </div>
      </div>
    </div>
  );
};

export default PodcastDesign;
